/** 对ES6内置API做兼容处理 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { RouterProvider } from "react-router-dom";
import { Suspense } from "react";
import "./index.css";
import createRoutes from "./routers/create-routes";
import newRoutes from "./routers";

function App() {
  return (
    <Suspense fallback={<div />}>
      <RouterProvider router={createRoutes(newRoutes)}></RouterProvider>
    </Suspense>
  );
}

export default App;
