import loadable from "@loadable/component";
import { MenuDataItem, IComponent } from "./typings";

export type IRoute = {
  path: string;
  routes?: IRoute[];
  component?: IComponent | any;
  exact?: boolean;
  key?: string;
  redirect?: string;
  strict?: boolean;
  sensitive?: boolean;
  Routes?: React.ComponentType<any>[] | any;
  /** 隐藏所有子Menu */
  hideChildrenInMenu?: boolean;
  /** 隐藏当前itemMenu */
  hideInMenu?: boolean;
  authority?: string[] | string;
  locale?: string;
  renderType?: "footer";
  /**
   * 标题
   * 默认会取当前路由配置的title 如果没有title则默认取name的值
   * 如果hideChildrenInMenu值为true，则子路由的title规则为： 优先使用自己的title 父级title 父级name
   */
  title?: string;
  subTitle?: string;
  /** 是否需要判断是否是广告主 */
  judgeAdvertisersRoute?: boolean;
  /** 是否需要固定导航栏 */
  stickyNavigate?: boolean;
};

const notFound = {
  path: "*",
  component: loadable(() => import("../components/NotFound")),
};

const routes: MenuDataItem[] = [
  {
    component: loadable(() => import("../layout")),
    setErrorElement: true,
    setNestErrorElement: true,
    routes: [
      {
        path: "/",
        name: "首页",
        component: loadable(() => import("../pages/Home")),
      },
      // {
      //   path: "ps",
      //   name: "烫画软件",
      //   routes: [
      //     {
      //       path: "/ps",
      //       redirect: "/ps/PSLayout",
      //       exact: true,
      //     },
      //     {
      //       path: "PSLayout",
      //       name: "PS烫画自动排版",
      //       component: loadable(() => import("../pages/PS/PSLayout")),
      //     },
      //     {
      //       path: "automaticMontai",
      //       name: "自动蒙泰",
      //       component: loadable(() => import("../pages/PS/AutomaticMontai")),
      //     },
      //     notFound,
      //   ],
      // },
      {
        path: "contact",
        name: "软件定制开发",
        component: loadable(() => import("../pages/Contact")),
      },
      notFound,
    ],
  },
  notFound,
];

const filterBoolean = (data: MenuDataItem[]) => {
  return data.filter(Boolean).map((item) => {
    if (item?.routes) {
      item.routes = filterBoolean(item.routes);
    }
    return item;
  });
};

const newRoutes = filterBoolean(routes);

export default newRoutes;
