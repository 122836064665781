import React, { createElement } from "react";
import {
  Route,
  Navigate,
  createRoutesFromElements,
  createBrowserRouter,
} from "react-router-dom";
import { MenuDataItem } from "./typings";
import ErrorPage from "../components/ErrorPage";

// 创建组件元素
function createComponentElement(route: MenuDataItem) {
  let { component: Component, routes, path } = route;
  if (Component) {
    return createElement(Component, { routes, path });
  }
}

// 创建Route元素
function createRoute(
  {
    path,
    index,
    caseSensitive,
    uuid,
    setErrorElement,
    setNestErrorElement,
    ...rest
  }: MenuDataItem,
  i: number
) {
  const key = uuid || path || i;
  const props = index ? { index: true } : { path };

  // 重定向
  if (rest.redirect) {
    return createElement(Route, {
      key,
      ...props,
      caseSensitive,
      element: createElement(Navigate, {
        replace: true,
        to: rest.redirect,
      }),
    });
  }

  let childRoute: any = createRouteList(rest.routes);

  if (setNestErrorElement) {
    childRoute = createElement(
      Route,
      { errorElement: <ErrorPage /> },
      createRouteList(rest.routes)
    );
  }

  return createElement(
    Route,
    {
      key,
      ...props,
      caseSensitive,
      element: createComponentElement({ ...rest, path }),
      errorElement: setErrorElement ? <ErrorPage /> : null,
      loader: rest.loader,
      action: rest.action,
    },
    childRoute
  );
}

// 创建route列表
export function createRouteList(
  routes?: MenuDataItem[]
): null | React.ReactNode[] {
  if (routes && routes.length) {
    return routes.map((item, index) => createRoute(item, index));
  }
  return null;
}

// 创建routes元素
export default function createRoutes(routes?: MenuDataItem[]) {
  return createBrowserRouter(createRoutesFromElements(createRouteList(routes)));
}
